<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banner from "@/components/widgets/banner";
import Stat from "@/components/widgets/stat";
import Binary from "@/components/widgets/binary-info";
import ChartBonus from "@/components/widgets/chart-bonus";
import ChartPoints from "@/components/widgets/chart-points";
import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
      'An e-mail has been sent with instructions for confirmation!': 'Foi enviado um e-mail com instruções de confirmação!'
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
      'An e-mail has been sent with instructions for confirmation!': 'Se ha enviado un correo electrónico con instrucciones para la confirmación!'
    }
  },
  components: { Layout, Banner, Stat, Binary, ChartBonus, ChartPoints },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: 'R$ -,--',
        balance: 'R$ -,--',
      },

      graduation: {
        name: '-',
        points: '-.--',
      },

      statData: null,
      wallets: null,

      notifications: true,

      plan: {
        modal: false,
        name: '',
        description: '',
      },

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        subtotal: 'R$ 0,00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: 'R$ 0,00'
      },
      emailValidation: false
    };
  },
  methods: {
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.bonus = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = response.data.balance
            this.getStatData();
          }
        })
    },
    getStatData() {
      if (this.account.user.package.access == 'full') {
        this.statData = [
          {
            icon: "package",
            title: "Package",
            value: this.account.user.package.name,
          },
          {
            icon: "balance",
            title: "Current Balance",
            value: this.total.balance,
          },
          {
            icon: "bonus",
            title: "Total Bonus",
            value: this.total.bonus
          }
        ]
      } else {
        this.statData = [
          {
            icon: "package",
            title: "Package",
            value: this.account.user.package.name,
          }
        ]
      }
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api
          .get('user/notifications')
          .then(response => {
            if (response.data.status==='success') {
              if (response.data.devices == 'active') {
                this.notifications = true
              } else {
                this.notifications = false
              }
            } else {
              this.notifications = false
            }
          });
      }
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status=='success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
    sendEmail() {
      api
        .get('user/email/validation')
        .then(response => {
          if (response.data.status == 'success') {
            this.$store.state.account.user.email.status = 'waiting'
            this.$noty.success(this.t(response.data.message))
          }
        })
    },
    getEmailStatus() {
      this.emailValidation = true
      api
      .get('user/email/status')
      .then((response) => {
        if (response.data.status == 'success') {
          this.emailValidation = true
        } else {
          this.emailValidation = false

        }
      })
    },
    getPlan() {
      api
        .get('user/plan')
        .then(response => {
          if (response.data.status == 'success') {
            if (response.data.description) {
              this.plan.modal = true
              this.plan.name = response.data.name
              this.plan.description = response.data.description
            }
          }
        })
    },
  },
  mounted() {
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.checkNotifications()
    this.getCart()
    this.getEmailStatus()
    this.getPlan()
  }
};
</script>

<template>
  <Layout>
    <div v-if="!emailValidation" class="card" @click="sendEmail()" style="cursor: pointer">
      <div class="card-body bg-soft-success rounded">
        <div class="d-flex align-items-center">
          <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
          <div>Seu e-mail ainda não foi confirmado. Clique <b>aqui</b> para confirmar.</div>
        </div>
      </div>
    </div>

    <router-link v-if="!notifications" to="/account/notifications" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-info rounded">
          <div class="d-flex align-items-center">
            <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
            <div>Fique informado de todas as atualizações do seu escritório virtual, ative suas notificações Web Push!</div>
          </div>
        </div>
      </div>
    </router-link>

    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img class="rounded-circle header-profile-user m-2 mx-2" :src="cart.items.list[Object.keys(cart.items.list)[0]].image" width="30px" height="30px">
            <div class="py-3">Você deixou alguns produtos em seu carrinho, clique aqui para finalizar o seu pedido!</div>
          </div>
        </div>
      </div>
    </router-link>

    <Banner></Banner>

    <div class="d-block d-sm-none">
      <div class="row text-center mb-3">
        <div class="col-4">
          <router-link :to="'/register/'+account.user.username" class="text-dark">
            <div class="bg-primary rounded-circle mb-2 p-3 d-inline-block"><i class="bx bxs-user-plus align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Register') }}</div>
          </router-link>
        </div>
        <div class="col-4">
          <router-link to="/wallet/extract" class="text-dark">
            <div class="bg-primary rounded-circle mb-2 p-3 d-inline-block"><i class="bx bx-list-check align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Extract') }}</div>
          </router-link>
        </div>
        <div class="col-4">
          <router-link to="/wallet/withdrawal" class="text-dark">
            <div class="bg-primary rounded-circle mb-2 p-3 d-inline-block"><i class="bx bx-wallet-alt align-middle text-white h1 mb-0"></i></div>
            <div class="d-block font-size-11">{{ t('Withdrawal') }}</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-12 col-lg-4 col-xl-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <Binary v-if="account.user.package.access == 'full'"></Binary>

    <div class="row" v-if="account.user.package.access == 'full'">
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark">
              <i class="bx bx-line-chart mr-1"></i> {{ t('Total Bonus') }}
            </h5>
            <ChartBonus></ChartBonus>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark">
              <i class="bx bx-line-chart mr-1"></i> {{ t('Total Points') }}
            </h5>
            <ChartPoints></ChartPoints>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="account.user.package.access == 'full'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t('Referral link') }}
            </h5>
            <p class="m-0 notranslate">
              https://office.orbyyexperience.com/{{ account.user.username }}
              <button class="btn btn-link p-0 ml-2" v-clipboard:copy="'https://office.orbyyexperience.com/' + account.user.username"><i class="bx bx-copy font-size-18 text-dark"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="plan.modal" :title="plan.name" centered hide-footer>
      <div v-html="plan.description"></div>
    </b-modal>
  </Layout>
</template>
