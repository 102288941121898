<script>
import { api } from "@/state/services";

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      banner: null,
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    getBanner() {
      api
      .get('banner')
      .then((response) => {
        this.banner = response.data.list
      })
    }
  },
  mounted() {
    this.getBanner()
  },
  computed: {
    monitorWidth: function() {
      var largura = screen.width;
      return largura;
    }
  }
}
</script>

<template>
  <div v-if="banner && banner.length > 0">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="300"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="bann in banner" :key="bann.index">
        <template v-slot:img>
            <div v-if="monitorWidth < 700">
              <img
                class="d-block img-fluid w-100"
                width="1024"
                height="300"
                :src="bann.mobile"
                :alt="bann.title"
              >
            </div>
            <div v-else>
              <img
                class="d-block img-fluid w-100"
                width="1024"
                height="300"
                :src="bann.desktop"
                :alt="bann.title"
              >
            </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>