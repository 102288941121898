<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Binary': 'Binário',
      'Points': 'Pontos',
      'Binary Info': 'Informações do Binário',
      'STATUS': 'STATUS',
      'QUALIFIED': 'QUALIFICADO',
      'UNQUALIFIED': 'NÃO QUALIFICADO',
      'LEFT TEAM': 'EQUIPE ESQUERDA',
      'RIGHT TEAM': 'EQUIPE DIREITA',
      'PERCENT': 'PORCENTAGEM',
      'BONUS FORECAST': 'PREVISÃO DE BÔNUS',
      'Binary Configuration': 'Configuração do Binário',
      'Side': 'Lado',
      'Left': 'Esquerdo',
      'Right': 'Direito',
      'Change': 'Alterar',
      'Success': 'Sucesso',
      'The side has been changed.': 'O lado foi alterado.'
    },
    es: {
      'Binary': 'Binario',
      'Points': 'Puntos',
      'Binary Info': 'Información Binaria',
      'STATUS': 'ESTADO',
      'QUALIFIED': 'CALIFICADO',
      'UNQUALIFIED': 'NO CUALIFICADO',
      'LEFT TEAM': 'EQUIPO IZQUIERDA',
      'RIGHT TEAM': 'EQUIPO DERECHA',
      'PERCENT': 'POR CIENTO',
      'BONUS FORECAST': 'PREVISIÓN DE BONIFICACIÓN',
      'Binary Configuration': 'Configuración Binaria',
      'Side': 'Lado',
      'Left': 'Izquierdo',
      'Right': 'Derecho',
      'Change': 'Cambiar',
      'Success': 'Éxito',
      'The side has been changed.': 'El lado se ha cambiado.',
    }
  },
  components: {  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      alert: {
        binary: { type: '', message: '' },
      },

      binaryInfo: {
        loading: true,
        errored: false,
        empty: false,
        status: '',
        team: {
          left: '0',
          right: '0',
        },
        points: {
          left: '0',
          right: '0',
        },
        percent: '0%',
        bonus: 'US$ 0,00',
      },

      binaryConfig: {
        selected: '',
        options: {
          'l': 'Left',
          'r': 'Right'
        },
        side: 'l'
      },
    }
  },
  validations: {
    binary: {},
  },
  methods: {
    getBinaryInfo() {
      api
        .get('network/binary/'+this.account.user.id)
        .then(response => {
          if (response.data.status=='success') {
            this.binaryInfo.status = response.data.points.status
            this.binaryInfo.team.left = response.data.users.left
            this.binaryInfo.team.right = response.data.users.right
            this.binaryInfo.points.left = response.data.points.current.left
            this.binaryInfo.points.right = response.data.points.current.right
            this.binaryInfo.percent = response.data.points.percent
            this.binaryInfo.bonus = response.data.points.bonus
          } else {
            this.binaryInfo.status = 'UNQUALIFIED'
          }
        })
        .catch(error => {
          this.binaryInfo.errored = error
          this.binaryInfo.loading = false
        })
        .finally(() => {
          this.binaryInfo.errored = false
          this.binaryInfo.loading = false
        })
    },
    getBinarySide() {
      api
        .get('network/binary/side')
        .then(response => {
          if (response.data.status=='success') {
            this.binaryConfig.side = response.data.side
            this.binaryConfig.selected = response.data.side
          }
        })
    },
    binaryConfigSubmit() {
      this.$v.binary.$touch();
      if (this.$v.binary.$invalid) {
        return;
      } else {
        api
          .post('network/binary/side',{
            side: this.binaryConfig.side
          })
          .then(response => {
            if (response.data.status=='success') {
              Swal.fire({
                title: this.t('Success'),
                text: this.t(response.data.message),
                icon: 'success',
                confirmButtonColor: '#34c38f',
                confirmButtonText: 'OK'
              })
            }
          })
      }
    }
  },
  mounted() {
    this.getBinaryInfo()
    this.getBinarySide()
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body pb-0">
          <h5 class="mb-3">
            <i class="bx bx-street-view mr-1"></i> {{ t('Binary Info') }}
          </h5>
          <div class="row border2 d-flex align-items-center">
            <div class="col-12 col-lg-4 pb-4 text-center">
              <h5>{{ t('STATUS') }}</h5>
              <p v-if="this.binaryInfo.status === 'QUALIFIED'" class="border border-success p-2 notranslate rounded font-weight-bold text-success mb-4" style="line-height:15px;">{{ t('QUALIFIED') }}</p>
              <p v-else class="border border-primary p-2 notranslate rounded font-weight-bold text-primary mb-4" style="line-height:15px;">{{ t('UNQUALIFIED') }}</p>
              <h5>{{ t('PERCENT') }}</h5>
              <p class="border border-dark p-2 notranslate rounded font-weight-bold mb-4" style="line-height:15px;">{{ this.binaryInfo.percent }}</p>
              <h5>{{ t('BONUS FORECAST') }}</h5>
              <p class="border border-success text-success p-2 notranslate rounded font-weight-bold mb-4" style="line-height:15px;">{{ this.binaryInfo.bonus }}</p>
            </div>
            <div class="col-12 col-lg-4 pb-4">
              <div class="p-4 text-center border border-primary rounded">
                <h5 class="text-primary mb-3">{{ t('LEFT TEAM') }} <i class="mdi mdi-arrow-left-bold"></i></h5>
                <h5 class="mb-0 notranslate">{{ this.binaryInfo.team.left }}</h5>
                <h6 class="mb-3">{{ t('Team') }}</h6>
                <h5 class="mb-0 notranslate">{{ this.binaryInfo.points.left }}</h5>
                <h6>{{ t('Points') }}</h6>
              </div>
            </div>
            <div class="col-12 col-lg-4 pb-4">
              <div class="p-4 text-center border border-primary rounded">
                <h5 class="text-primary mb-3"><i class="mdi mdi-arrow-right-bold"></i> {{ t('RIGHT TEAM') }}</h5>
                <h5 class="mb-0 notranslate">{{ this.binaryInfo.team.right }}</h5>
                <h6 class="mb-3">{{ t('Team') }}</h6>
                <h5 class="mb-0 notranslate">{{ this.binaryInfo.points.right }}</h5>
                <h6>{{ t('Points') }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-h-fix">
        <div class="card-body">
          <h5 class="mb-3">
            <i class="bx bx-cog mr-1"></i> {{ t('Binary Configuration') }}
          </h5>
          <div v-if="alert.binary.message" :class="'alert ' + alert.binary.type">{{alert.binary.message}}</div>
          <b-form @submit.prevent="binaryConfigSubmit" class="form-leg w-fit mx-auto">
            <div class="leg-radio" v-for="(option,index) in binaryConfig.options" :key="index">
              <input type="radio" :id="index" :value="index" v-model="binaryConfig.side" :checked="binaryConfig.side == index">
              <label :for="index">{{ t(option) }}</label>
            </div>
            <b-button type="submit" variant="primary" class="w-lg btn-lg btn-block">{{ t('Change') }}</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>